import { graphql } from "gatsby"
import React from "react"

import InitiativeApplication from "../../components/career/initiative-application/initiative-application"
import RecruitingProcessHeroTile from "../../components/career/recruiting-process-hero-tile/recruiting-process-hero-tile"
import RecruitingProcessStepsTile from "../../components/career/recruiting-process-steps/recruiting-process-steps-tile/recruiting-process-steps-tile"
import SEO from "../../components/seo"
import SideNavView from "../../components/views/side-nav-view/side-nav-view"
import { useTranslation } from "../../i18n/translate"

const RecruitingProcessPageHoc = ({ location, data }) => {
  const { t } = useTranslation()
  return <RecruitingProcessPage t={t} location={location} data={data} />
}

class RecruitingProcessPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t, location, data } = this.props

    return (
      <>
        <SEO
          pathName={location.pathname}
          titleTranslatedString={t("CAREER-RECRUITING_PROCESS-META_TITLE")}
          descriptionTranslatedString={t(
            "CAREER-RECRUITING_PROCESS-META_DESCRIPTION",
          )}
        />
        <SideNavView
          titleTranslatedString={t("MENU-CAREER-RECRUITING_PROCESS_PAGE_TITLE")}
          pathName={location.pathname}
        >
          <div className="_fp-grid _fp-grid--gap">
            <div className="_fp-col-12">
              <RecruitingProcessHeroTile
                gatsbyImage={data.recruitingProcessImage}
              />
            </div>
            <div className="_fp-col-12">
              <RecruitingProcessStepsTile />
            </div>
            <InitiativeApplication
              titleTranslatedString={t("CAREER-INITIATIVE_APPLICATION-TITLE")}
              linkPath={"https://munzing.softgarden.io/get-connected"}
              linkTextTranslatedString={t(
                "CAREER-INITIATIVE_APPLICATION-APPLY_NOW",
              )}
              textTranslatedString={t(
                "CAREER-INITIATIVE_APPLICATION-DESCRIPTION",
              )}
            />
          </div>
        </SideNavView>
      </>
    )
  }
}

export default RecruitingProcessPageHoc

export const query = graphql`
  {
    recruitingProcessImage: strapiDynamicImage(
      uid: { eq: "RECRUITING_PROCESS" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
