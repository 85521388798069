import React from "react"

import useNav from "../../../hooks/useNav"
import { useTranslation } from "../../../i18n/translate"
import { dynamicImageWithGatsbyImage } from "../../../util/dynamic-image"
import TextWithOrWithoutGallery from "../../dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import { GalleryPictureItem } from "../../gallery/model/gallery-item"
import Tile from "../../tile/tile"
import TileContentSpacer from "../../tile/tile-content-spacer/tile-content-spacer"
import "./recruiting-process-hero-tile.sass"

const RecruitingProcessHeroTile = ({ gatsbyImage }) => {
  const { t } = useTranslation()

  const openVacanciesNav = useNav({ uid: "open-vacancies" })

  return (
    <div className={"_fp-recruiting-process-hero-tile"}>
      <Tile
        linkTextTranslatedString={t(
          "CAREER-OPEN_VACANCIES-VIEW_OPEN_VACANCIES",
        )}
        linkPath={openVacanciesNav.url}
      >
        <h2 className={"_fp-heading-1"}>
          {t("CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS_AT_MUNZING")}
        </h2>
        <TileContentSpacer size={"extra-small"} />
        <TextWithOrWithoutGallery
          gallery={[
            new GalleryPictureItem({
              gatsbyFluidImage: dynamicImageWithGatsbyImage(gatsbyImage),
            }),
          ]}
          textTranslationKey={t(
            "CAREER-RECRUITING_PROCESS-RECRUITING_PROCESS_DESCRIPTION",
          )}
        ></TextWithOrWithoutGallery>
        <TileContentSpacer></TileContentSpacer>
      </Tile>
    </div>
  )
}

export default RecruitingProcessHeroTile
